// 点击 产品介绍 x5 x6 子母机-转化
const gtag_report_products_view = (url) => {
    const callback = function () {
        // if (typeof url !== 'undefined') {
        //     window.location = url;
        // }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-16665125894/0sRCCMrf1MgZEIbIxoo-',
        'value': 0.1,
        'currency': 'USD',
        'event_callback': callback
    });
    return false;
};

// 浏览首页-转化
function gtag_report_home_page_visit(url) {
    const callback = function () {
        printWelcomeMessage();
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-16665125894/nCLbCNn6-9EZEIbIxoo-',
        'event_callback': callback
    });

    return false;
}


function gtag_report_view_about(url) {
    var callback = function () {
        // if (typeof(url) != 'undefined') {
        //     window.location = url;
        // }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-16665125894/K7cDCLSn78gZEIbIxoo-',
        'value': 0.1,
        'currency': 'USD',
        'event_callback': callback
    });
    return false;
}

function gtag_report_whatsapp_contact() {
    var callback = function () {
        console.log("google-tags:whatsapp uploaded")
    };

    window.gtag('event', 'conversion', {
        'send_to': 'AW-16665125894/eNQQCNKk68sZEIbIxoo-',
        'value': 0.1,
        'currency': 'USD',
        'event_callback': callback
    });
    return false
}


function printWelcomeMessage() {
    console.clear();
    console.log(`%c
██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███╗   ██╗███████╗
██║  ██║██╔══██╗████╗  ██║██╔═══██╗████╗  ██║██╔════╝
███████║███████║██╔██╗ ██║██║   ██║██╔██╗ ██║███████╗
██╔══██║██╔══██║██║╚██╗██║██║   ██║██║╚██╗██║╚════██║
██║  ██║██║  ██║██║ ╚████║╚██████╔╝██║ ╚████║███████║
╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
`, "font-family:monospace; color: #1976d2; font-size: 14px;");

    console.log("%cWelcome to the official address of the Xiaomai Spreader!", "font-weight: bold; color: #ffb700;");
}

export {
    printWelcomeMessage,
    gtag_report_products_view,
    gtag_report_view_about,
    gtag_report_whatsapp_contact,
    gtag_report_home_page_visit
};
